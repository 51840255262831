import { useState } from "react";
import classes from './TransferNFT.module.css'
import companyLogoWithName from '../../assets/images/logo_with_name.svg'
import closeIcon from '../../assets/icons/close.svg'
import nftBadge from '../../assets/icons/nft_badge.svg'
import { transferNFT } from "../../utils/api";
import { generateToken } from "../../utils/utils";
import Loading from "../Loading/Loading";

const TransferNFT = (props) => {

  const handleCancel = () => {
    props.closeModal()
  };

  const [formValues, setFormValues] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [transferNftResponse, setTransferNftResponse] = useState({})
  const [error, setError] = useState('');
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  const handleChange = (e) => {

    const walletAddressRegex = /^0x[a-fA-F0-9]{40}$/;

    if (e.target.value && !walletAddressRegex.test(e.target.value)) {
      setError('Please enter a valid wallet address')
    } else if (e.target.value === userInfo.sca) {
      setError('You cannot transfer to your own address')
    } else {
      setError('')
    }

    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const handleTransferNft = async () => {
    try {
      const walletAddress = formValues?.walletAddress;
      if (walletAddress) {
        setShowLoading(true)
        const idToken = (await generateToken())?.id_token;
        if (idToken) {
          const apiPayload = {
            toAddress: walletAddress
          }
          const response = await transferNFT(idToken, apiPayload)
          const responseData = response?.data;

          setTransferNftResponse(responseData)
          if(!responseData?.url) {
            props.setPendingUserOpHash(responseData?.uoHash)
          }
          await props.fetchTransactions()

          setShowLoading(false)
        }
      }
    } catch (error) {
      setShowLoading(false)
      console.error(error)
    }
  }

  return (
    <>
      <Loading show={showLoading} ></Loading>
      <div className={`${classes.fullScreenModalContainer} ${props.showTransferNftModal && classes.blur}`}>
        <div className={classes.modal} role="dialog" tabIndex="-1" aria-hidden="true">
          <div className={classes.modalContent}>
            <div className={classes.headerSection}>
              <span className={classes.modalTitle}>Transfer your NFT</span>
              <div className={classes.closeButton} onClick={handleCancel}>
                <img src={closeIcon} alt="close"></img>
              </div>
            </div>
            {!Object.keys(transferNftResponse).length ? (
              <div className={classes.body}>
                <div className={classes.content}>
                  <div className={classes.nftSection}>
                    <div className={classes.nftContent}>
                      <div className={classes.nftImage}></div>
                      <div className={classes.nftTitleAndBadge}>
                        <div className={classes.nftTitle}>Eagle Rise 2024</div>
                        <div className={classes.nftBadgeSection}>
                          <div className={classes.nftBadgeContent}>
                            <img src={nftBadge} alt="Badge" className={classes.badgeIcon}></img>
                            <span className={classes.nftBadgeText}>You Own This</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.toText}>(To)</div>
                  <input
                    type="text"
                    id="walletAddress"
                    className={classes.emailAddressSection}
                    placeholder="Enter wallet address"
                    autoComplete="off"
                    value={formValues.walletAddress}
                    onChange={handleChange}
                  />
                  {error && <span className={classes.error}>{error}</span>}
                </div>
                <button
                  className={classes.submitSection}
                  onClick={() => handleTransferNft()}
                  disabled={formValues?.walletAddress && !error ? false : true}
                >
                  <span className={classes.transferButton}>Transfer</span>
                </button>
              </div>
            ) : (
              <div className={classes.body}>
                <div className={classes.infoTextSection}>
                  <span>Token transferred successfully! </span>
                  {transferNftResponse?.url ? (
                    <span>Click <a className={classes.txUrl} href={transferNftResponse?.url} target="_blank" rel="noopener noreferrer">here</a> to view the transaction</span>
                  ) : (
                    <span>Please wait a moment while the transaction is being confirmed.</span>
                  )}
                </div>
              </div>
            )}

            <div className={classes.footer}>
              <div className={classes.footerText}>Powered by</div>
              <div>
                <img alt="Byzanlink" src={companyLogoWithName}></img>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TransferNFT