import axios from 'axios';
import { getConfig } from '../config/config';

const config = getConfig();
const BACKEND_URL = config.REACT_APP_BACKEND_URL;

export const getSCA = (token) => {
  const url = BACKEND_URL + '/api/v1/smartAccount';

  return axios({
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

export const claimNFT = (token, data) => {
  const url = BACKEND_URL + '/api/v1/token/claim';

  return axios({
    url,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

export const transferNFT = (token, data) => {
  const url = BACKEND_URL + '/api/v1/token/transfer';

  return axios({
    url,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  })
}

export const getTransactions = async (token) => {
  try {

    const url = BACKEND_URL + '/api/v1/transactions';

    const response = await axios({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export const getWalletInfo = async (token, walletAddress) => {
  try {
    const url = `${BACKEND_URL}/api/v1/token/balanceOf?accountAddress=${walletAddress}`;

    const response = await axios({
      url,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    })
    return response
  } catch (error) {
    return error
  }
}

export const generateAuthTokens = (refreshToken) => {

  const url = `https://${config.AUTH0_DOMAIN}/oauth/token`

  const data = new FormData()
  data.append('grant_type', 'refresh_token')
  data.append('client_id', config.AUTH0_CLIENT_ID)
  data.append('refresh_token', refreshToken)

  return axios({
    url,
    method: 'post',
    maxBodyLength: Infinity,
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}