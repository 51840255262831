import { getConfig } from "../config/config";
import { generateAuthTokens } from "./api";

const config = getConfig();
const auth0LocalStorageKey = `@@auth0spajs@@::${config.AUTH0_CLIENT_ID}::default::openid profile email offline_access`

export const shortenWalletAddress = (address, length = 4) => {
    if (!address) {
        return
    }
    if (!address.startsWith('0x')) {
        address = '0x' + address;
    }
    const start = address.substring(0, length + 2); // Include '0x' prefix
    const end = address.substring(address.length - length);
    return start + '....' + end;
}

export const generateToken = async () => {
    try {
        const auth0LocalStorageData = JSON.parse(window.localStorage.getItem(auth0LocalStorageKey.toString()));
        const refreshToken = auth0LocalStorageData?.body?.refresh_token;
        
        const tokenData = await generateAuthTokens(refreshToken);
        auth0LocalStorageData.body.refresh_token = tokenData?.data?.refresh_token
        
        window.localStorage.setItem(auth0LocalStorageKey, JSON.stringify(auth0LocalStorageData))
        return tokenData?.data
    } catch (error) {
        console.error('Error in generateToken', error)
        throw error
    }

}   