import { useState } from 'react';
import classes from './ReceiveFunds.module.css';
import QRCode from 'react-qr-code';

import copyButton from '../../assets/icons/copy.svg';
import { shortenWalletAddress } from '../../utils/utils';

const ReceiveFunds = (props) => {

  const [isCopied, setIsCopied] = useState(false);
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  const handleCopyClick = async () => {
    const text = userInfo?.sca;
    if ("clipboard" in navigator) {
      setIsCopied(true)
      return await navigator.clipboard.writeText(text)
    }
  }

  return (
    <div className={`${classes.fullScreenModalContainer} ${props.showReceiveFundsModal && classes.blur}`}>
      <div className={classes.modal} role="dialog" tabIndex="-1" aria-hidden="true">
        <div className={classes.modalContent}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderLeft}>
              <div className={classes.arrowBackwardButton} onClick={() => props.handleReceiveFundsBackButton()}></div>
              <span className={classes.modalHeaderTitle}>Receive Funds</span>
            </div>
            <div className={classes.modalHeaderRight}>
              <div className={classes.closeButton} onClick={() => props.handleReceiveFundsCloseButton()}></div>
            </div>
          </div>
          <div className={classes.modalBody}>
            <div className={classes.qrCodeSection}>
              <div className={classes.qrCode}>
                <QRCode
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                  value={userInfo?.sca}
                />
              </div>
              <span className={classes.modalBodyText}>Scan this QR or copy the wallet address to send funds to this wallet</span>
            </div>
            <div className={classes.walletAddressSection}>
              <span className={classes.walletAddress}>{shortenWalletAddress(userInfo?.sca)}</span>
              <img
                className={classes.copyButton}
                src={copyButton}
                alt="Copy"
                title={(isCopied && "Copied") || `Copy`}
                onClick={handleCopyClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default ReceiveFunds;