import classes from './Loading.module.css';
import loadingGif from '../../assets/gifs/loading_animation.gif';

const Loading = (props) => {
  return (
    props.show && (
      <div className={`${classes.fullScreenModalContainer} ${classes.blur}`}>
      <div className={classes.modal}>
      <img src={loadingGif} alt='Loading' />
      </div>
      </div>
    )
  )
}

export default Loading;