import classes from "./ClaimNFT.module.css";
import { useState } from "react";
import nft from '../../assets/images/nft.png';
import Authentication from "../../modals/Authentication/Authentication";

const ClaimNFT = (props) => {

  const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);

  const handleAuthenticationModal = (value) => {
    setShowAuthenticationModal(value)
  }

  return (
    <>
      <div className={classes.frame}>
        < p className={classes.titleSection} >
          <span className={classes.titleText1}>Start Your Fight - </span>
          <span className={classes.alternateTitleText1}>Start Your Fight</span>
          <span className={classes.titleText2}>Claim Your Eagle</span>
        </p >
        <div className={classes.nftSection}>
          <div className={classes.nft}>
            <div className={classes.overlap_group}>
              <img className={classes.nftImage} alt="Eagle NFT" src={nft} />
              <div className={classes.group}>
                <div className={classes.div_wrapper}>
                  <div className={classes.nftDescription}>Eagle Rise 2024</div>
                </div>
              </div>
            </div>
          </div>
          <button className={classes.claimButton} onClick={() => handleAuthenticationModal(true)}>
            <span className={classes.claimButtonText}>Claim Now</span>
          </button>
        </div>
      </div >
      <Authentication
        setIsLoggedIn={(value) => props.setIsLoggedIn(value)}
        closeModal={() => handleAuthenticationModal(false)}
        showAuthenticationModal={showAuthenticationModal}
      >
      </Authentication>
    </>
  );
};

export default ClaimNFT
