import classes from './UserProfile.module.css';
import logoutIcon from '../../assets/icons/logout.svg';
import copyIcon from '../../assets/icons/copy.svg';
import sendIcon from '../../assets/icons/send.svg';
import receiveIcon from '../../assets/icons/download.svg';
import addIcon from '../../assets/icons/add.svg';
import closeIcon from '../../assets/icons/close.svg';

import { useState } from 'react';
import { shortenWalletAddress } from '../../utils/utils';

import { useAuth0 } from '@auth0/auth0-react';

const UserProfile = (props) => {

  const { logout } = useAuth0();

  const [isCopied, setIsCopied] = useState(false);
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  const handleCopyClick = async () => {
    const text =userInfo?.sca;
    if ("clipboard" in navigator) {
      setIsCopied(true)
      return await navigator.clipboard.writeText(text)
    }
  }

  const handleReceiveFundsModal = (value) => {
    props.closeUserProfileModal()
    props.handleReceiveFundsModal(value)
  }

  const handleWalletDisconnect = () => {
    logout()
    props.setIsLoggedIn(false);
    props.closeUserProfileModal()
    window.localStorage.removeItem('userInfo')
    window.localStorage.removeItem('idToken')
  }

  return (
    <div className={`${classes.fullScreenModalContainer} ${props.showUserProfileModal && classes.blur}`}>
      <div className={classes.modal} role="dialog" tabIndex="-1" aria-hidden="true">
        <div className={classes.modalContent}>
          <div className={classes.modalBody}>
            <div className={classes.userProfileContainer}>
            <div className={classes.userProfileSection}>
              <div className={classes.userProfile}>
                <img className={classes.userProfileImage} src={userInfo.profilePic} alt="Profile"></img>
                <div className={classes.userDetails}>
                  <div className={classes.walletAddressSection}>
                    <span className={classes.userName}>{userInfo.name}</span>
                    <div className={classes.walletAddressAndCopyButton}>
                      <span className={classes.walletAddress}>{shortenWalletAddress(userInfo?.sca)}</span>
                      <img
                        className={classes.copyButton}
                        src={copyIcon}
                        alt="Copy"
                        title={(isCopied && "Copied") || `Copy`}
                        onClick={handleCopyClick}
                      />
                    </div>
                  </div>
                  <span className={classes.walletBalance}>0.000 ETH</span>
                </div>
              </div>
            </div>
            <div>
              <img className={classes.closeIcon} src={closeIcon} alt="Close" onClick={ () => props.closeUserProfileModal()} />
            </div>
            </div>
              <div className={classes.walletConnectionSection}>
                <span className={classes.walletConnectionStatus}></span>
                <span className={classes.walletConnectionText}>Connected to Smart Wallet</span>
              </div>
            
            <div className={classes.walletOperationsSection}>
              <button className={`${classes.actionButton} ${classes.disabledActionButton}`}>
                <div className={classes.actionIconAndText}>
                  <img className={classes.actionIcon} src={sendIcon} alt="Send"></img>
                  <span className={classes.actionText}>Send</span>
                </div>
              </button>
              <button className={classes.actionButton} onClick={() => handleReceiveFundsModal(true)}>
                <div className={classes.actionIconAndText}>
                  <img className={classes.actionIcon} src={receiveIcon} alt="Receive"></img>
                  <span className={classes.actionText}>Receive</span>
                </div>
              </button>
              <button className={`${classes.actionButton} ${classes.disabledActionButton}`}>
                <div className={classes.actionIconAndText}>
                  <img className={classes.actionIcon} src={addIcon} alt="Buy"></img>
                  <span className={classes.actionText}>Buy</span>
                </div>
              </button>
            </div>
          </div>
          <button className={classes.disconnectButtonSection} onClick={handleWalletDisconnect}>
            <div className={classes.disconnectButtonBody}>
              <img className={classes.logoutIcon} src={logoutIcon} alt="Logout"></img>
              <span className={classes.logoutText}>Disconnect Wallet</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserProfile;