import classes from './Dashboard.module.css'
import { useEffect, useState } from 'react'
import nftBadge from '../../assets/icons/nft_badge.svg'
import arrowForward from '../../assets/icons/arrow_forward.svg';
import companyLogo from '../../assets/icons/bzl_logo.svg';
import checkCircleGreenIcon from '../../assets/icons/check_circle_green.svg'
import whiteChecked from '../../assets/icons/white_checked.svg'
import orangeCircle from '../../assets/icons/orange_circle.svg'
import dummyProfile from '../../assets/icons/dummy_profile.png'
import doubleArrowForward from '../../assets/icons/double_arrow_forward.svg';

import TransferNFT from '../../modals/TransferNFT/TransferNFT'
import { getTransactions, getWalletInfo } from '../../utils/api';
import { Tooltip } from 'react-tooltip';
import { useAuth0 } from '@auth0/auth0-react';

const NFTSection = (props) => {

  const { getIdTokenClaims, logout } = useAuth0()

  const [showTransferNftModal, setShowTransferNftnModal] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [pendingUserOpHash, setPendingUserOpHash] = useState(null);

  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'))

  const handleTransferNFTModal = () => {
    setShowTransferNftnModal(true)
  }

  const closeModal = () => {
    setShowTransferNftnModal(false)
  }

  async function getBalance() {
    const idToken = (await getIdTokenClaims())?.__raw?.toString()
    const response = await getWalletInfo(idToken, userInfo?.sca)
    if (response?.response?.status === 401) {
      window.localStorage.removeItem('userInfo')
      logout()
    }
    setTokenBalance(response?.data?.tokenBalance || 0)
  }

  useEffect(() => {
    getBalance()
  }, [])

  useEffect(() => {
    if (!pendingUserOpHash) return;

    const refreshIntervalId = setInterval(async () => {
      const idToken = (await getIdTokenClaims())?.__raw?.toString()
      const response = await getTransactions(idToken)
      const transactions = response?.data?.data?.length ? response?.data?.data : []
      const transferTransaction = transactions.find(x => x?.uoHash === pendingUserOpHash);
      if (transferTransaction?.txHash) {
        clearInterval(refreshIntervalId)
        setPendingUserOpHash(null);
        props.setExistingTransactions(transactions)
        getBalance()
      }
    }, 2000)

    setTimeout(() => {
      clearInterval(refreshIntervalId);
    }, 120000);

  }, [pendingUserOpHash])

  return (
    <>
      <div className={classes.nftSection}>
        <div className={classes.frame}>
          <div className={classes.nftTitleSection}>
            <div className={classes.titleContent}>
              <span className={classes.title1}>{tokenBalance ? '🎉 Congrats! Eagle claimed.' : '🎉 Token claimed and transferred successfully'}</span> <br></br>
              <span className={classes.title2}>{tokenBalance ? 'Your adventure begins—exciting updates ahead! 🚀' : ''}</span>
            </div>
          </div>
          <div className={classes.nftImageAndBadge}>
            <div className={classes.nftImageSection}>
              <div className={classes.nftImage}></div>
              <div className={classes.nftName}>Eagle Rise 2024</div>
              <div className={classes.nftBadgeSection}>
                <div className={classes.nftBadgeContent}>
                  <img src={nftBadge} alt="Badge" className={classes.badgeIcon}></img>
                  <span className={classes.nftBadgeText}>{tokenBalance ? 'You Own This' : 'Transferred'}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${classes.buttonSection} ${!tokenBalance && classes.disabledButton}`}>
            <button className={classes.transferButton} onClick={() => handleTransferNFTModal()}>
              <span className={classes.transferButtonText}>Transfer</span>
            </button>
          </div>
        </div>
      </div>
      {showTransferNftModal &&
        <TransferNFT
          fetchTransactions={props.fetchTransactions}
          setPendingUserOpHash={setPendingUserOpHash}
          showTransferNftModal={showTransferNftModal}
          closeModal={closeModal}>
        </TransferNFT>
      }
    </>
  )
}

const NFTActivity = (props) => {

  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  useEffect(() => {
    props.fetchTransactions()
  }, [])

  function handleViewTransaction(data) {
    window.open(data.explorerUrl + data.txHash, '_blank')
  }

  return (
    <div className={classes.activitySection}>

      <div className={classes.activityTitle}>Activity </div>
      <div className={classes.activityTableForMobileScreen}>
        {props?.existingTransactions?.length && props?.existingTransactions?.map(tx => (
          tx.type === 'claim' ? (
            <div className={classes.activityContainer}>
              <div onClick={() => handleViewTransaction(tx)} className={`${classes.activityStatusSection} ${classes.claimedStatus}`}>
                <img src={whiteChecked} alt='Claimed' />
                <p className={classes.activityStatusSectionMobile}>Claimed</p>
                <img src={arrowForward} alt='View' />
              </div>
              <div className={classes.activityDetailsSection}>
                <div className={classes.userSection}>
                  <img className={classes.profileIcon} src={companyLogo} alt='Profile Pic' ></img>
                  <p className={classes.username}>Byzanlink</p>
                </div>
                <img src={doubleArrowForward} alt='To' />
                <div className={classes.userSection}>
                  <img className={classes.profileIcon} src={userInfo.profilePic} alt='Profile Pic' ></img>
                  <p className={classes.username}>{userInfo.firstName}</p>
                </div>
              </div>
            </div>

          ) : (
            <div className={classes.activityContainer}>
              <div onClick={() => handleViewTransaction(tx)} className={`${classes.activityStatusSection} ${classes.transferredStatus}`}>
                <img src={whiteChecked} alt='Claimed' />
                <p className={classes.activityStatusSectionMobile}>Transferred</p>
                <img src={arrowForward} alt='View' />
              </div>
              <div className={classes.activityDetailsSection}>
                <div className={classes.userSection}>
                  <img className={classes.profileIcon} src={userInfo.profilePic} alt='Profile Pic' ></img>
                  <p className={classes.username}>{userInfo.firstName}</p>
                </div>
                <img src={doubleArrowForward} alt='To' />
                <div className={classes.userSection}>
                  <img className={classes.profileIcon} src={dummyProfile} alt='Profile Pic' ></img>
                  <p className={classes.username}>External</p>
                </div>
              </div>
            </div>
          )
        ))}
      </div>
      <div className={classes.activityTable}>
        <div className={classes.headingSection}>
          <span className={classes.tableHeading}>From</span>
          <span className={classes.tableHeading}>To</span>
          <span className={classes.tableHeading}>Type</span>
        </div>
        {props.existingTransactions?.length && props.existingTransactions.map((transaction, index) => (
          <div className={classes.activityTableRows}>
            <div className={classes.activityData}>
              <div className={classes.activityDetails}>
                {transaction.type === 'claim' ? (
                  <>
                    <div className={classes.activityFrom}>
                      <img className={classes.profileIcon} src={companyLogo} alt='Profile Pic'></img>
                      <span className={classes.username}>Byzanlink</span>
                    </div>
                    <div className={classes.activityTo}>
                      <img className={classes.profileIcon} src={userInfo.profilePic} alt={'Profile Pic'}></img>
                      <span className={classes.username}>{userInfo.firstName}</span>
                    </div>
                    <div className={classes.activityType}>
                      <img className={classes.activityStatusIcon} src={checkCircleGreenIcon} alt='Claimed'></img>
                      <span className={classes.activityStatusText}>Claimed</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classes.activityFrom}>
                      <img className={classes.profileIcon} src={userInfo.profilePic} alt='Profile Pic'></img>
                      <span className={classes.username}>{userInfo.firstName}</span>
                    </div>
                    <div className={classes.activityTo}>
                      <img className={classes.profileIcon} src={dummyProfile} alt={'Profile Pic'}></img>
                      <span className={classes.username}>External</span>
                    </div>
                    <div className={classes.activityType}>
                      <img className={classes.activityStatusIcon} src={orangeCircle} alt='Transferred'></img>
                      <span className={classes.activityStatusText}>{transaction?.txHash ? 'Transferred' : 'Pending'}</span>
                    </div>
                  </>
                )}

              </div>
              <Tooltip
                anchorSelect={`.arrowTooltip${index+1}`}
                place='bottom'
                effect='solid'
                variant='light'
                content={transaction?.txHash ? 'View Transaction' : 'Pending'}
              >
              </Tooltip>
              <div className={`${classes.arrowForwardButtonSection} arrowTooltip${index+1}`}>
                <img
                  data-tooltip-id='test'
                  className={classes.arrowForward}
                  style={{ cursor: transaction?.txHash ? 'pointer' : 'not-allowed' }}
                  src={arrowForward}
                  alt="Click"
                  onClick={() => transaction?.txHash && handleViewTransaction(transaction)}
                />
              </div>
            </div>
          </div>
        )
        )}
      </div>
    </div>
  )
}

const Dashboard = () => {

  const { getIdTokenClaims, logout } = useAuth0()
  const [existingTransactions, setExistingTransactions] = useState([])

  async function fetchTransactions() {
    const idToken = (await getIdTokenClaims())?.__raw?.toString()
    const transactionData = await getTransactions(idToken)

    if (transactionData?.response?.status === 401) {
      window.localStorage.removeItem('userInfo')
      logout()
    }
    if (transactionData?.data?.data?.length) {
      setExistingTransactions(transactionData?.data?.data)
    }
  }

  return (
    <div className={classes.body}>
      <NFTSection fetchTransactions={fetchTransactions} setExistingTransactions={setExistingTransactions} ></NFTSection>
      <NFTActivity existingTransactions={existingTransactions} fetchTransactions={fetchTransactions}></NFTActivity>
    </div>
  )
}

export default Dashboard