import { useEffect, useState } from "react";
import classes from './Authentication.module.css'

import googleLogo from '../../assets/icons/google_logo.svg'
import appleLogo from '../../assets/icons/apple_logo.svg'
import fbLogo from '../../assets/icons/fb_logo.png'
import companyLogoWithName from '../../assets/images/logo_with_name.svg'
import companyLogo from '../../assets/icons/bzl_logo.svg'
import closeIcon from '../../assets/icons/close.svg'

import { useAuth0 } from "@auth0/auth0-react";
import { claimNFT, getSCA } from "../../utils/api";
import Loading from "../Loading/Loading";
import { generateToken } from "../../utils/utils";

const Authentication = (props) => {

  const { getIdTokenClaims, loginWithPopup, error, user } = useAuth0();
  const [showLoading, setShowLoading] = useState(false);
  const [auth, setAuth] = useState(false);

  const postLogin = async () => {

    if (!auth) return

    if (error) {
      console.log('Error in login', error.message)
      setShowLoading(false);
    }
    else {

      const idToken = (await getIdTokenClaims()).__raw?.toString();

      if (idToken) {

        setShowLoading(true)

        const { email, name, given_name, picture } = user;
        const response = await getSCA(idToken)

        const { sca, isNewUser } = response?.data?.payload;

        const userInfo = {
          email,
          name,
          firstName: given_name,
          profilePic: picture,
          sca,
          isNewUser
        }

        if (isNewUser) {
          const apiPayload = {
            toAddress: sca
          }
          const tokenData = await generateToken();
          await claimNFT(tokenData?.id_token, apiPayload)
        }

        window.localStorage.setItem('userInfo', JSON.stringify(userInfo))
        window.localStorage.setItem('idToken', JSON.stringify(idToken))

        props.setIsLoggedIn(true)
        props.closeModal();
        setShowLoading(false);
      }
    }
    setAuth(false);
  }

  useEffect(() => {
    postLogin();
  }, [auth])

  const login = async (connectionType) => {
    try {
      const options = {
        connection: connectionType,
        prompt: 'select_account'
      };

      await loginWithPopup({ authorizationParams: options });
      setAuth(true);

    } catch (err) {
      console.error(err)
    }
  };

  const handleCancel = () => {
    props.closeModal()
  };

  const [formValues, setFormValues] = useState({});

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  return (
    <>
      <Loading show={showLoading} ></Loading>
      {props.showAuthenticationModal &&
        <div className={`${classes.fullScreenModalContainer} ${props.showAuthenticationModal && classes.blur}`}>
          <div className={classes.modal} role="dialog" tabIndex="-1" aria-hidden="true">
            <div className={classes.modalContent}>
              <div className={classes.header}>
                <div className={classes.modalTitleSection}>
                  <img className={classes.companyLogo} alt="Byzanlink" src={companyLogo}></img>
                  <span className={classes.companyName}>Byzan Connect</span>
                </div>
                <div className={classes.closeButton} onClick={handleCancel}>
                  <img src={closeIcon} alt="close"></img>
                </div>
              </div>
              <div className={classes.body}>
                <div className={classes.signInSection}>
                  <div className={classes.signInOptions}>
                    <div className={classes.ssoOptions}>
                      <button className={classes.logoSection} onClick={() => login('google-oauth2')}>
                        <img className={classes.googleLogo} src={googleLogo} alt="Google"></img>
                      </button>
                      <button className={classes.logoSection} onClick={() => login('apple')}>
                        <img className={classes.googleLogo} src={appleLogo} alt="Apple"></img>
                      </button>
                      <button className={classes.logoSection} onClick={() => login('facebook')}>
                        <img className={classes.googleLogo} src={fbLogo} alt="Facebook"></img>
                      </button>
                    </div>
                    <div className={classes.orSection}>
                      <div className={classes.orText}>(Or)</div>
                    </div>
                    <input
                      type="text"
                      id="email"
                      autoComplete="off"
                      disabled
                      className={classes.emailSection}
                      placeholder="Enter your email address"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </div>
                  <button className={classes.submitSection}>
                    <span className={classes.continueButton}>Continue</span>
                  </button>
                </div>
                <div className={classes.footer}>
                  <div className={classes.footerText}>Powered by</div>
                  <div>
                    <img alt="Byzanlink" src={companyLogoWithName}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Authentication