import classes from './Body.module.css'
import { Fragment } from 'react';
import ClaimNFT from '../ClaimNFT/ClaimNFT';
import Dashboard from '../Dashboard/Dashboard';

const Body = (props) => {
  
  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
  
  return (
    <Fragment>
      {!props.isLoggedIn && !userInfo?.sca ?
        <div className={classes.nftSection}>
          <ClaimNFT setIsLoggedIn={(value) => props.setIsLoggedIn(value)}></ClaimNFT>
        </div>
        :
        <Dashboard></Dashboard>
      }
    </Fragment>
  )
}

export default Body