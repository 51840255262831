import { useState } from 'react';
import './App.css';
import Body from './components/Body/Body';
import Header from './components/Header/Header';

function App() {
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <div className="App">
      <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}></Header>
      <Body isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}></Body>
    </div>
  );
}

export default App;
