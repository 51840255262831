import classes from './Header.module.css'
import logo from '../../assets/images/logo_horizontal_dark.svg';
import securityLogo from '../../assets/icons/security.svg'
import copyIcon from '../../assets/icons/copy.svg'
import { useState } from 'react';
import UserProfile from '../../modals/UserProfile/UserProfile';
import ReceiveFunds from '../../modals/ReceiveFunds/ReceiveFunds';
import { shortenWalletAddress } from '../../utils/utils';

const Header = (props) => {

  const userInfo = JSON.parse(window.localStorage.getItem('userInfo'));

  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [showReceiveFundsModal, setShowReceiveFundsModal] = useState(false);

  const handleReceiveFundsBackButton = () => {
    setShowReceiveFundsModal(false);
    setShowUserProfileModal(true)
  }

  const handleReceiveFundsCloseButton = () => {
    setShowReceiveFundsModal(false);
    setShowUserProfileModal(false)
  }

  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = async () => {
    const text = userInfo.sca;
    if ("clipboard" in navigator) {
      setIsCopied(true)
      return await navigator.clipboard.writeText(text)
    }
  }

  return (
    <>
    <div className={classes.header}>
      <div className={classes.logoPlaceholder}>
        <img src={logo} className={classes.originalLogo} alt="logo" />
      </div>
      {(props?.isLoggedIn || userInfo?.sca) &&
        <div className={classes.profileSection}>
          <div className={classes.profileContent}>
            <div className={classes.accountAddressSection}>
              <img className={classes.securityLogo} src={securityLogo} alt="Security"></img>
              <div
                className={classes.accountAddress}
                onClick={() => setShowUserProfileModal(true)}
              >
                {shortenWalletAddress(userInfo?.sca)}
              </div>
            </div>
            <img
              className={classes.copyIcon}
              src={copyIcon}
              alt="Copy"
              title={(isCopied && "Copied") || `Copy`}
              onClick={handleCopyClick}
            />
          </div>
        </div>
      }
    </div>
    {showUserProfileModal &&
        <UserProfile
          closeUserProfileModal={() => setShowUserProfileModal(false)}
          handleReceiveFundsModal={(value) => setShowReceiveFundsModal(value)}
          setIsLoggedIn={() => props.setIsLoggedIn(false)}
          showUserProfileModal={showUserProfileModal}
        >
        </UserProfile>}

      {showReceiveFundsModal &&
        <ReceiveFunds
          handleReceiveFundsBackButton={handleReceiveFundsBackButton}
          handleReceiveFundsCloseButton={handleReceiveFundsCloseButton}
          showReceiveFundsModal={showReceiveFundsModal}
        >
        </ReceiveFunds>
      }
    </>
  )
}

export default Header